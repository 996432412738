<template>
  <div>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? "编辑客户" : "新增客户" }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-form-model-item prop="number" label="客户编号">
            <a-input v-model="form.number" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="name" label="客户名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="warehouse" label="仓库">
            <WarehouseSelect v-model="form.warehouse" />
          </a-form-model-item>
          <a-form-model-item prop="reservoir_area" label="库区">
            <ReservoirAreaSelect v-model="form.reservoir_area" :warehouse="form.warehouse" />
          </a-form-model-item>
          <a-form-model-item prop="contacts" label="联系人">
            <a-input v-model="form.contacts" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="电话">
            <a-input v-model="form.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" label="邮箱">
            <a-input v-model="form.email" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="地址">
            <a-input v-model="form.address" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-textarea v-model="form.remark" />
          </a-form-model-item>
          <a-form-model-item prop="is_active" label="状态">
            <a-select v-model="form.is_active" style="width: 100%">
              <a-select-option :value="true">激活</a-select-option>
              <a-select-option :value="false">冻结</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item prop="delivery_method_list" label="配送方式">
            <a-select v-model="form.delivery_method_list" mode="multiple" style="width: 100%">
              <a-select-option value="快递">快递</a-select-option>
              <a-select-option value="物流">物流</a-select-option>
              <a-select-option value="市内配送">市内配送</a-select-option>
              <a-select-option value="自提">自提</a-select-option>
              <a-select-option value="闪送">闪送</a-select-option>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item prop="packaging_requirement_list" label="包装要求">
            <a-select v-model="form.packaging_requirement_list" mode="multiple" style="width: 100%">
              <a-select-option value="常规">常规</a-select-option>
              <a-select-option value="原箱">原箱</a-select-option>
              <a-select-option value="打托盘">打托盘</a-select-option>
              <a-select-option value="泡沫箱">泡沫箱</a-select-option>
              <a-select-option value="加冰">加冰</a-select-option>
              <a-select-option value="包隔热膜">包隔热膜</a-select-option>
              <a-select-option value="包棉被">包棉被</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="courier_company_list" label="承运公司">
            <a-select v-model="form.courier_company_list" mode="multiple" style="width: 100%">
              <a-select-option value="市内配送">市内配送</a-select-option>
              <a-select-option value="顺丰快递">顺丰快递</a-select-option>
              <a-select-option value="德邦快递">德邦快递</a-select-option>
              <a-select-option value="中通快递">中通快递</a-select-option>
              <a-select-option value="京东快递">京东快递</a-select-option>
              <a-select-option value="京东物流">京东物流</a-select-option>
              <a-select-option value="德邦物流">德邦物流</a-select-option>
              <a-select-option value="亚德物流">亚德物流</a-select-option>
              <a-select-option value="顺丰同城">顺丰同城</a-select-option>
              <a-select-option value="货拉拉">货拉拉</a-select-option>
              <a-select-option value="闪送">闪送</a-select-option>
              <a-select-option value="自提">自提</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="enable_import_update_material" label="产品导入更新">
            <a-select v-model="form.enable_import_update_material" style="width: 100%">
              <a-select-option :value="true">开启</a-select-option>
              <a-select-option :value="false">关闭</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>

        <div v-if="!form.id" style="color: rgb(255, 77, 79); text-align: center">默认初始密码为: 123456, 登录后请修改密码</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { clientCreate, clientUpdate, getClientNumber } from "@/api/data";
import { rules } from "./rules";

export default {
  components: {
    WarehouseSelect: () => import("@/components/WarehouseSelect"),
    ReservoirAreaSelect: () => import("@/components/ReservoirAreaSelect2"),
  },
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
      form: {
        delivery_method_list: ["快递", "物流", "市内配送", "自提", "闪送"],
        packaging_requirement_list: ["常规"],
        courier_company_list: ["顺丰", "德邦", "中通"],
      },
    };
  },
  methods: {
    confirm() {
      this.form.id ? this.update() : this.create();
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = { ...this.form };

          this.loading = true;
          clientCreate(formData)
            .then((data) => {
              this.$message.success("新增成功");
              this.$parent.items = this.$functions.insertItem(this.$parent.items, data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    update() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = { ...this.form };

          this.loading = true;
          clientUpdate(formData)
            .then((data) => {
              this.$message.success("修改成功");
              this.$parent.items = this.$functions.replaceItem(this.$parent.items, data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        if (this.item.id) {
          this.form = { ...this.item };
        } else {
          getClientNumber().then((data) => {
            this.form = { ...this.form, is_active: true, number: data.number };
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
